import { InjectionKey } from 'vue'
import { ActionTree, createStore, GetterTree, MutationTree, Store } from 'vuex'
import { dashboardApi, ServerResponse } from '@/api/DashboardApi'
import { User } from '@/model/User'
import { Project } from '@/model/Project'

export interface StoreStateUser {
  user: User
  users: User[]
  projects: Project[]
  isLogged: boolean
  image: string
}

export enum StoreGettersUser {
  USER = 'USER',
  USERS = 'USERS',
  PROJECTS = 'PROJECTS',
  IS_LOGGED = 'IS_LOGGED',
  IMAGE = 'IMAGE',
}

export enum StoreActionsUser {
  GET = 'GET',
  GET_BY_PROJECT = 'GET_BY_PROJECT',
  GET_PROJECTS = 'GET_PROJECTS',
  GET_ALL = 'GET_ALL',
  ADD = 'ADD',
  DELETE = 'DELETE',
  GET_IMAGE = 'GET_IMAGE',
  LINK_TO_PROJECT = 'LINK_TO_PROJECT',
  UNLINK_TO_PROJECT = 'UNLINK_TO_PROJECT',
  UPDATE = 'UPDATE',
  UPDATE_IMAGE = 'UPDATE_IMAGE',
  DELETE_IMAGE = 'DELETE_IMAGE',
  ASK_ACTIVATION = 'ASK_ACTIVATION',
}

export enum StoreMutationsUser {
  SET_USER = 'SET_USER',
  SET_USERS = 'SET_USERS',
  SET_PROJETS = 'SET_PROJETS',
  PUSH_USER = 'PUSH_USER',
  DELETE_USER = 'DELETE_USER',
  SET_IMAGE = 'SET_IMAGE',
}

const state: StoreStateUser = {
  user: {},
  users: [],
  projects: [],
  isLogged: false,
  image: '',
}

const getters: GetterTree<StoreStateUser, StoreStateUser> = {
  [StoreGettersUser.USER](state): User {
    return state.user
  },
  [StoreGettersUser.USERS](state): User[] {
    return state.users
  },
  [StoreGettersUser.PROJECTS](state): Project[] {
    return state.projects
  },
  [StoreGettersUser.IS_LOGGED](state): boolean {
    return state.isLogged
  },
  [StoreGettersUser.IMAGE](state): string {
    return state.image
  },
}

const actions: ActionTree<StoreStateUser, StoreStateUser> = {
  async [StoreActionsUser.GET](
    { commit },
    userId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.get(`users/${userId}`)
    if (json.success) {
      commit(StoreMutationsUser.SET_USER, json.data)
    }
    return json
  },
  async [StoreActionsUser.GET_BY_PROJECT](
    { commit },
    projectId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.get(`projects/${projectId}/users`)
    commit(StoreMutationsUser.SET_USERS, json.data)
    return json
  },
  async [StoreActionsUser.GET_PROJECTS](
    { commit },
    userId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.get(`users/${userId}/projects`)
    if (json.success) {
      commit(StoreMutationsUser.SET_PROJETS, json.data)
    }
    return json
  },
  async [StoreActionsUser.GET_ALL]({ commit }): Promise<ServerResponse> {
    const json = await dashboardApi.get('users')
    commit(StoreMutationsUser.SET_USERS, json.data)
    return json
  },
  async [StoreActionsUser.ADD](
    { commit },
    user: User,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post('users/register', user)
    commit(StoreMutationsUser.PUSH_USER, json.data)
    return json
  },
  async [StoreActionsUser.DELETE](
    { commit },
    userId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.delete(`users/${userId}`)
    if (json.success) {
      commit(StoreMutationsUser.DELETE_USER, userId)
    }
    return json
  },
  async [StoreActionsUser.UPDATE_IMAGE](
    { commit },
    { base64, userId, file },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(`users/${userId}/image`, {
      base64,
      file,
    })
    if (json.success) {
      commit(StoreMutationsUser.SET_IMAGE, json.data)
    }
    return json
  },
  async [StoreActionsUser.GET_IMAGE](
    { commit },
    userId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.get(`users/${userId}/image`)
    if (json.success) {
      commit(StoreMutationsUser.SET_IMAGE, json.data)
    }
    return json
  },
  async [StoreActionsUser.LINK_TO_PROJECT](
    { commit },
    { userId, projectId },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(
      `users/${userId}/linkToProject/${projectId}`,
    )
    return json
  },
  async [StoreActionsUser.UNLINK_TO_PROJECT](
    { commit },
    { userId, projectId },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(
      `users/${userId}/unlinkToProject/${projectId}`,
    )
    return json
  },
  async [StoreActionsUser.UPDATE](
    { commit },
    { id, user },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.put(`users/${id}`, user)
    if (json.success) {
      commit(StoreMutationsUser.SET_USER, json.data)
    }
    return json
  },
  async [StoreActionsUser.DELETE_IMAGE](
    { commit },
    userId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.delete(`users/${userId}/image`)
    if (json.success) {
      commit(StoreMutationsUser.SET_IMAGE, '')
    }
    return json
  },
  async [StoreActionsUser.ASK_ACTIVATION](
    state,
    userId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(`users/${userId}/ask-activation`)
    return json
  },
}

const mutations: MutationTree<StoreStateUser> = {
  [StoreMutationsUser.SET_USER](state, user: User): void {
    state.user = user
  },
  [StoreMutationsUser.SET_USERS](state, users: User[]): void {
    state.users = users
  },
  [StoreMutationsUser.SET_PROJETS](state, projects: Project[]): void {
    state.projects = projects
  },
  [StoreMutationsUser.PUSH_USER](state, user: User): void {
    if (user) state.users.push(user)
  },
  [StoreMutationsUser.DELETE_USER](state, userId: number): void {
    state.users = state.users.filter((u) => u.id !== userId)
  },
  [StoreMutationsUser.SET_IMAGE](state, image: string): void {
    state.image = image
  },
}

export const key: InjectionKey<Store<StoreStateUser>> = Symbol('')

export const storeUser = createStore<StoreStateUser>({
  state,
  mutations,
  getters,
  actions,
})
