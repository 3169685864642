import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6f387e10")
const _hoisted_1 = { class: "user-form" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value", "disabled", "label"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["value", "label"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = ["value"]
const _hoisted_10 = ["value"]
const _hoisted_11 = ["value"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("dig-input-text", {
      value: _ctx.user.username,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update('username', $event.target.value))),
      id: "username",
      required: ""
    }, _toDisplayString(_ctx.$t('User.username')), 41, _hoisted_2),
    _createElementVNode("dig-input-text", {
      value: _ctx.user.email,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update('email', $event.target.value))),
      id: "email",
      type: "email",
      required: ""
    }, _toDisplayString(_ctx.$t('User.email')), 41, _hoisted_3),
    (!_ctx.fixProjectId)
      ? (_openBlock(), _createElementBlock("dig-input-select-picker", {
          key: 0,
          value: _ctx.user.projectId,
          onSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.update('projectId', $event.detail))),
          disabled: !_ctx.canChooseProject,
          label: _ctx.$t('project')
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
            return (_openBlock(), _createElementBlock("dig-input-select-picker-option", {
              key: project.key,
              value: project.key
            }, _toDisplayString(project.label), 9, _hoisted_5))
          }), 128))
        ], 40, _hoisted_4))
      : _createCommentVNode("", true),
    _createElementVNode("dig-input-radio-wrapper", {
      value: _ctx.user.role,
      onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.update('role', $event.detail))),
      label: _ctx.$t('User.role'),
      required: ""
    }, [
      (_ctx.isSuperAdmin)
        ? (_openBlock(), _createElementBlock("dig-input-radio", {
            key: 0,
            value: _ctx.USER_ROLES.SUPERADMIN
          }, _toDisplayString(_ctx.$t('Roles.superadmin')), 9, _hoisted_7))
        : _createCommentVNode("", true),
      (_ctx.isSuperAdmin)
        ? (_openBlock(), _createElementBlock("dig-input-radio", {
            key: 1,
            value: _ctx.USER_ROLES.DEV
          }, _toDisplayString(_ctx.$t('Roles.developper')), 9, _hoisted_8))
        : _createCommentVNode("", true),
      (_ctx.isSuperAdmin)
        ? (_openBlock(), _createElementBlock("dig-input-radio", {
            key: 2,
            value: _ctx.USER_ROLES.VISITOR
          }, _toDisplayString(_ctx.$t('Roles.visitor')), 9, _hoisted_9))
        : _createCommentVNode("", true),
      _createElementVNode("dig-input-radio", {
        value: _ctx.USER_ROLES.ADMIN
      }, _toDisplayString(_ctx.$t('Roles.admin')), 9, _hoisted_10),
      _createElementVNode("dig-input-radio", {
        value: _ctx.USER_ROLES.USER
      }, _toDisplayString(_ctx.$t('Roles.user')), 9, _hoisted_11)
    ], 40, _hoisted_6)
  ]))
}