
import { defineComponent, computed, SetupContext } from 'vue'
import { Project } from '@/model/Project'
import { USER_ROLES, User } from '@/model/User'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { StoreGettersProject, storeProject } from '@/store/project'
import { KeyLabel } from '@/model/KeyLabel'

export default defineComponent({
  name: `UserForm`,
  components: {},
  props: {
    fixProjectId: {
      type: Number,
      required: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ['update'],
  setup(props: Readonly<any>, context: SetupContext) {
    const update = (key: keyof User, value: any) => {
      context.emit('update', { key, value })
    }

    const canChooseProject = computed((): boolean => {
      return props.user.role !== USER_ROLES.SUPERADMIN
    })

    const isSuperAdmin = computed((): boolean => {
      return storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN
    })

    const projects = computed((): KeyLabel[] => {
      return storeProject.getters[StoreGettersProject.PROJECTS].map(
        (project: Project) => {
          return {
            key: project.id,
            label: project.name,
          }
        },
      )
    })

    return { update, canChooseProject, isSuperAdmin, projects, USER_ROLES }
  },
})
