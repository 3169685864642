
import { defineComponent, SetupContext, Ref, ref, watch, onMounted } from 'vue'
import { Toaster } from '@/common/Toaster'
import { User } from '@/model/User'
import FormCard from '../FormCard.vue'
import UserForm from './UserForm.vue'
import { DigInputController } from '@digithia/input/common'
import { __ } from '@/locales'

export default defineComponent({
  name: `UserFormCard`,
  components: { FormCard, UserForm },
  props: {
    fixProjectId: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{
      fixProjectId?: number
      title?: string
      user?: User
    }>,
    context: SetupContext,
  ) {
    const userForm: Ref<User> = ref({})

    onMounted(() => {
      userForm.value = JSON.parse(JSON.stringify(props.user))
    })

    watch(
      () => props.user,
      () => {
        userForm.value = JSON.parse(JSON.stringify(props.user))
      },
    )

    const update = ({ key, value }: { key: keyof User; value: any }) => {
      userForm.value[key] = value
    }

    const validate = () => {
      if (DigInputController.checkInputsValidity('.form-card')) {
        context.emit('validate', userForm.value)
      } else {
        Toaster.toast({
          type: 'warning',
          message: __(`invalidForm`),
        })
      }
    }

    const cancel = () => {
      userForm.value = JSON.parse(JSON.stringify(props.user))
      context.emit('cancel')
    }

    return { userForm, update, validate, cancel }
  },
})
